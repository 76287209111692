<template>
    <main>
        <data-grid @search="search($event)" :data="pagination">
            <template #grid>
                <grid-table>
                    <template #head>
                        <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                    </template>
                    <template #body>
                        <grid-row v-for="(servicio, i) in pagination.data" :key="`d_${i}`">
                            <grid-cell class="text-center">
                               {{servicio.codigo}}
                            </grid-cell>
                            <grid-cell>
                                {{servicio.descripcion}}
                            </grid-cell>
                            <grid-cell>
                                {{(servicio.agrupacion || {}).codigo}} {{(servicio.agrupacion || {}).descripcion}}
                            </grid-cell>
                            <grid-cell class="text-center">
                                <button class="btn btn-sm btn-icon btn-success" title="Seleccionar" @click="selected(i)">
                                    <i class="fas fa-check"></i>
                                </button>
                            </grid-cell>
                        </grid-row>
                    </template>
                </grid-table>
            </template>
        </data-grid>
    </main>
</template>

<script>

import DataGrid from "./utilities/DataGrid/DataGrid";
import GridTable from "./utilities/DataGrid/GridTable";
import GridCellHeader from "./utilities/DataGrid/GridCellHeader";
import GridRow from "./utilities/DataGrid/GridRow";
import GridCell from "./utilities/DataGrid/GridCell";
import servicioService from "../../services/servicioService";
import paginateMixin from "../../mixins/PaginateMixin";

export default {
    components: {GridCell, GridRow, GridCellHeader, GridTable, DataGrid},
    mixins : [paginateMixin(servicioService)],
    data(){
        return {
            headers : ['Codigo','Descripcion','Agrupacion',''],
        }
    },
    created() {
        this.index();
    },
    methods : {
        selected(index){
            const selected = this.pagination.data[index];
            this.$emit('select', selected);
        }
    }
}
</script>

<style scoped>

</style>
