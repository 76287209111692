<template>
    <main>
        <div class="modal fade" :id="random_modal_id" tabindex="-1" role="dialog" aria-labelledby="Buscar Causa Externa" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Buscar Causa Externa</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <component :is="component" @select="onSelect($event)"></component>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mr-0 pr-0 col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="input-group input-group-sm">
                    <input
                        type="text"
                        class="form-control"
                        :readonly="isSearch"
                        v-model="codigo"
                        v-on:keyup.enter.prevent="getByCode()"
                        :class="classObject"
                    />
                    <div class="input-group-append">
                        <button class="btn btn-primary" type="button" tabindex="-1"
                                :data-target="`#${random_modal_id}`"
                                data-toggle="modal"
                        @click="component='SearchCausaExternaComponent'">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="ml-0 pl-1 col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="nombreCausa"
                    disabled
                />
            </div>
        </div>
    </main>
</template>
<script>
import ServiceFactory from "../../services/ServiceFactory";
import {isEmpty} from 'lodash';
import $ from 'jquery';

const CausaExternaService = ServiceFactory.get("causa-externa");
import SearchCausaExternaComponent from "./SearchCausaExternaComponent";

export default {
    props: ["value", "default", "validator"],
    components : {SearchCausaExternaComponent},
    data() {
        return {
            codigo: "",
            causaExterna: this.value,
            isSearch: false,
            random_modal_id : '',
            component : '',
        };
    },
    created() {

        if (this.default !== "") {
            this.codigo = this.default;
            this.getByCode();
        }

        if(typeof this.value === 'string'){
            this.codigo = this.value;
            this.getByCode();
        }

        const random_number = Math.floor(Math.random() * ((100+1)-1)+1);
        this.random_modal_id = `search_causa_externa_modal_${random_number}`;
    },
    watch: {
        value: {
            handler: function (newValue) {

                if(typeof newValue === 'string'){
                    this.codigo = newValue;
                    this.getByCode();
                    return;
                }

                this.causaExterna = newValue;

                if (Object.entries(newValue).length === 0) {
                    this.codigo = "";
                }
            },
            deep: true,
        },
    },
    methods: {
        async getByCode() {
            this.isSearch = true;

            let response = null;

            response = await CausaExternaService.getByCode(this.codigo);

            this.isSearch = false;

            if (!isEmpty(response.data)) {
                this.causaExterna = response.data;
                this.$emit("input", this.causaExterna);
            }

            this.validate();
        },
        setChange(value) {
            this.$emit("update-value", value);
        },
        validate(){
            if(this.validator !== null && this.validator !== undefined){
                this.validator.$touch();
            }
        },
        onSelect(value){
            this.codigo = value.codigo;
            this.getByCode();
            $(`#${this.random_modal_id}`).modal('hide');
        }
    },
    computed: {
        nombreCausa: function () {
            return !isEmpty(this.causaExterna) ? this.causaExterna.descripcion : "";
        },
        classObject: function () {

            let _class = {
                "is-invalid": false,
                "is-valid": false,
            };

            if (this.validator !== undefined && this.validator !== null) {
                _class["is-invalid"] = this.validator.$error;
                _class["is-valid"] = !this.validator.$error && this.validator.$dirty;
            }

            return _class;
        }
    },
};
</script>
