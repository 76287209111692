<template>
    <main :ref="random_ref">
        <data-grid @search="search($event)" :data="pagination">
            <template #grid>
                <grid-table>
                    <template #head>
                        <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                    </template>
                    <template #body>
                        <grid-row v-for="(dx, i) in pagination.data" :key="`d_${i}`">
                            <grid-cell class="text-center">
                                {{dx.ID_DIAG}}
                            </grid-cell>
                            <grid-cell>
                                {{dx.DESC_DIAG}}
                            </grid-cell>
                            <grid-cell class="text-center">
                                <button class="btn btn-sm btn-icon btn-success" title="Seleccionar" @click="selected(i)">
                                    <i class="fas fa-check"></i>
                                </button>
                            </grid-cell>
                        </grid-row>
                    </template>
                </grid-table>
            </template>
        </data-grid>
    </main>
</template>

<script>

import DataGrid from "./utilities/DataGrid/DataGrid";
import GridTable from "./utilities/DataGrid/GridTable";
import GridCellHeader from "./utilities/DataGrid/GridCellHeader";
import GridRow from "./utilities/DataGrid/GridRow";
import GridCell from "./utilities/DataGrid/GridCell";
import Swal from 'sweetalert2/src/sweetalert2';
import diagnosticoService from "../../services/diagnosticoService";

export default {
    components: {GridCell, GridRow, GridCellHeader, GridTable, DataGrid},
    data(){
        return {
            headers : ['CIE10','Descripcion',''],
            random_ref : '',
            pagination : {
                data : [],
                current_page : 1,
                page : 1,
                total : 0,
                per_page : 5,
                search : '',
            }
        }
    },
    mounted() {
        const random_number = Math.floor(Math.random() * ((100+1)-1)+1);
        this.random_ref = `search_dx_${random_number}_container`;

        this.$nextTick(() => {this.index();});
    },
    watch : {
        'pagination.page' : {
            handler : function(){
                this.index();
            },
            deep : true
        },
        'pagination.per_page' :  {
            handler : function(){
                this.pagination.page = 1;
                this.index();
            },
            deep : true
        }
    },
    methods : {
        async index(){
            let loader = this.$loading.show({ color: '#0055ff', container: this.$refs[this.random_ref] });
            try{
                const response = ( await diagnosticoService.index(this.pagination.search, this.pagination) )?.data;
                this.pagination.data = response.data;
                this.pagination.total = response.total;
                this.pagination.current_page = response.current_page;

                loader.hide();
            }catch (e) {
                console.error(e);
                loader.hide();
                Swal.fire('Ocurrio un error al procesar la solicitud', '', 'error');
            }
        },
        search(query){
            this.pagination.search = query;
            this.index();
        },
        selected(index){
            const selected = this.pagination.data[index];
            this.$emit('select', selected);
        }
    }
}
</script>

<style scoped>

</style>
