<template>
  <main>
    <div class="row">
      <div class="col-6" v-if="fields.tipo_diagnostico">
        <div class="form-group">
          <label>Tipo Diagnostico</label>
          <tipo-diagnostico-field
            v-model="datos.tipo_diagnostico"
            :default="1"
            :validator="validator.id_tipo_diagnostico"
            @input="validator.id_tipo_diagnostico.$touch()"
          ></tipo-diagnostico-field>
        </div>
      </div>
      <div class="col-6" v-if="fields.finalidad">
        <div class="form-group">
          <label>Finalidad</label>
          <finalidad-field
            v-model="datos.finalidad"
            :default="10"
            :validator="validator.id_finalidad"
          ></finalidad-field>
        </div>
      </div>
      <div class="col-6" v-if="fields.causa_externa">
        <div class="form-group">
          <label>Causa Externa</label>
          <causa-externa-field
            v-model="datos.causa_externa"
            :default="13"
            :validator="validator.id_causa_externa"
          ></causa-externa-field>
        </div>
      </div>
      <div class="col-6" v-if="fields.diagnostico_ppal">
        <div class="form-group">
          <label>Dx. Ppal</label>
          <dx-field
            v-model="datos.diagnostico_ppal"
            :validator="validator.diagnostico_ppal"
            @input="validator.diagnostico_ppal.$touch()"
          >
          </dx-field>
        </div>
      </div>
      <div class="col-6" v-if="fields.diagnostico_r1">
        <div class="form-group">
          <label>Dx. Relacionado 1</label>
          <dx-field v-model="datos.diagnostico_r1"></dx-field>
        </div>
      </div>
      <div class="col-6" v-if="fields.diagnostico_r2">
        <div class="form-group">
          <label>Dx. Relacionado 2</label>
          <dx-field v-model="datos.diagnostico_r2"></dx-field>
        </div>
      </div>
      <div class="col-6" v-if="fields.diagnostico_r3">
        <div class="form-group">
          <label>Dx. Relacionado 3</label>
          <dx-field v-model="datos.diagnostico_r3"></dx-field>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import CausaExternaField from "../../../components/common/CausaExternaField.vue";
import DxField from "../../../components/common/DxField.vue";
import FinalidadField from "../../../components/common/FinalidadField.vue";
import TipoDiagnosticoField from "../../../components/common/TipoDiagnosticoField.vue";

export default {
  props: ["value", "fields", "validator"],
  components: {
    FinalidadField,
    CausaExternaField,
    TipoDiagnosticoField,
    DxField,
  },
  data() {
    return {
      rips: this.value,
      datos: {
        tipo_diagnostico: "",
        finalidad: 10,
        causa_externa: 13,
        diagnostico_ppal: "",
        diagnostico_r1: "",
        diagnostico_r2: "",
        diagnostico_r3: "",
      },
    };
  },
  watch: {
    datos: {
      handler: function () {

        this.rips = {
          id_tipo_diagnostico: this.datos.tipo_diagnostico.id,
          id_finalidad: this.datos.finalidad.id,
          id_causa_externa: this.datos.causa_externa.id,
          diagnostico_ppal: this.datos.diagnostico_ppal.ID_DIAG,
          diagnostico_r1: this.datos.diagnostico_r1.ID_DIAG,
          diagnostico_r2: this.datos.diagnostico_r2.ID_DIAG,
          diagnostico_r3: this.datos.diagnostico_r3.ID_DIAG,
        };

        this.$emit("input", this.rips);
      },
      deep: true,
    },
  },
  methods: {},
}
</script>
