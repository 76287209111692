<template>
    <main>
        <div class="row">
            <div class="form-group col-6">
                <label for="fecha_ingreso" class="small">Fecha Ingreso</label>
                <input class="form-control form-control-sm"
                       type="date"
                       id="fecha_ingreso"
                       v-model="datos.fecha_ingreso"
                       :class="{
                               'is-invalid': validator.fecha_ingreso.$error,
                               'is-valid': ! validator.fecha_ingreso.$error && validator.fecha_ingreso.$dirty
                           }">
            </div>
            <div class="form-group col-6">
                <label for="hora_ingreso" class="small">Hora Ingreso</label>
                <input class="form-control form-control-sm" type="time" id="hora_ingreso" v-model="datos.hora_ingreso"
                       :class="{
                 'is-invalid': validator.hora_ingreso.$error,
                 'is-valid': ! validator.hora_ingreso.$error && validator.hora_ingreso.$dirty
             }">
            </div>
            <div class="form-group col-6">
                <label for="fecha_salida" class="small">Fecha Salida</label>
                <input class="form-control form-control-sm" type="date" id="fecha_salida"
                       v-model="datos.fecha_salida"
                       :class="{
                 'is-invalid': validator.fecha_salida.$error,
                 'is-valid': ! validator.fecha_salida.$error && validator.fecha_salida.$dirty
             }">
            </div>
            <div class="form-group col-6">
                <label for="hora_salida" class="small">Hora Salida</label>
                <input class="form-control form-control-sm" type="time" id="hora_salida" v-model="datos.hora_salida"
                       :class="{
                 'is-invalid': validator.hora_salida.$error,
                 'is-valid': ! validator.hora_salida.$error && validator.hora_salida.$dirty
             }">
            </div>
            <div class="form-group col-6">
                <label for="via_ingreso" class="small">Via de ingreso</label>
                <select class="form-control form-control-sm" v-model="datos.via_ingreso" id="via_ingreso"
                        :class="{
                 'is-invalid': validator.via_ingreso.$error,
                 'is-valid': ! validator.via_ingreso.$error && validator.via_ingreso.$dirty
             }"
                >
                    <option v-for="(via, index) in vias_ingreso" :key="index" :value="via.codigo">{{via.descripcion}}</option>
                </select>
            </div>
            <div class="form-group col-6">
                <label class="small">Causa Externa</label>
                <causa-externa-field v-model="datos.causa_externa" :validator="validator.causa_externa"></causa-externa-field>
            </div>
            <div class="form-group col-6">
                <label class="small">Dx Ingreso</label>
                <dx-field v-model="datos.dx_ingreso" :validator="validator.dx_ingreso"></dx-field>
            </div>
            <div class="form-group col-6">
                <label class="small">Pabellon</label>
                <select class="form-control form-control-sm" v-model="datos.pabellon"
                        :class="{
                 'is-invalid': validator.pabellon.$error,
                 'is-valid': ! validator.pabellon.$error && validator.pabellon.$dirty
             }">
                    <option v-for="(pabellon , index) in pabellones" :value="pabellon.ID" :key="index">
                        {{pabellon.DESC_PABELLON}}
                    </option>
                    <option :value="null" disabled>-- Seleccione un pabellon --</option>
                </select>
            </div>
            <div class="form-group col-6">
                <label class="small">Habitacion</label>
                <select class="form-control form-control-sm" v-model="datos.habitacion"
                        :class="{
                 'is-invalid': validator.habitacion.$error,
                 'is-valid': ! validator.habitacion.$error && validator.habitacion.$dirty
             }">
                    <option v-for="(habitacion, index) in habitaciones" :value="habitacion.ID" :key="index">
                        {{habitacion.DESC_HABITACION}}
                    </option>
                    <option :value="null" disabled>-- Seleccione una habitacion --</option>
                </select>
            </div>
            <div class="form-group col-6">
                <label class="small">Cama</label>
                <select class="form-control form-control-sm"
                        v-model="datos.cama"
                        :class="{
                            'is-invalid': validator.cama.$error,
                            'is-valid': ! validator.cama.$error && validator.cama.$dirty
                        }"
                >
                    <option v-for="(cama, index) in camas" :value="cama.ID" :key="index">
                        {{cama.DESCRIPCION}}
                    </option>
                    <option :value="null" disabled>-- Seleccione una cama --</option>
                </select>
            </div>
            <div class="form-group col-6">
                <label class="small">Dx Salida</label>
                <dx-field v-model="datos.dx_salida" :validator="validator.dx_salida"></dx-field>
            </div>
            <div class="form-group col-6">
                <label class="small">Estado salida</label>
                <select class="form-control form-control-sm" v-model="datos.estado_salida"
                        :class="{
                 'is-invalid': validator.estado_salida.$error,
                 'is-valid': ! validator.estado_salida.$error && validator.estado_salida.$dirty}">
                    <option
                        v-for="(estado, index) in estados_salida"
                        :key="index"
                        :value="estado.codigo"
                    >
                        {{ estado.descripcion }}
                    </option>
                </select>
            </div>
        </div>
    </main>
</template>
<script>
import CausaExternaField from "../../../components/common/CausaExternaField";
import DxField from "../../../components/common/DxField";
import PabellonService from "../../../services/PabellonService";
import {isEmpty} from 'lodash';

export default {
    props : ["value", "validator"],
    components: {DxField, CausaExternaField},
    async created() {
        try{

            let response = await PabellonService.index();

            this.pabellones = response.data;

            if(!isEmpty(this.value)){

                this.datos = {
                    fecha_ingreso : this.value.fecha_ingreso,
                    hora_ingreso : this.value.hora_ingreso,
                    fecha_salida : this.value.fecha_salida,
                    hora_salida : this.value.hora_salida,
                    via_ingreso : this.value.via_ingreso,
                    dx_ingreso : this.value.dx_ingreso,
                    dx_salida : this.value.dx_salida,
                    pabellon : Number(this.value.pabellon),
                    habitacion : Number(this.value.habitacion),
                    cama : Number(this.value.cama),
                    causa_externa : String(this.value.causa_externa),
                    estado_salida : Number(this.value.estado_salida)
                }

            }

        }catch (e) {
            alert('Ocurrio un Error');
        }
    },
    data() {
        return {
            datos : {
                fecha_ingreso : '',
                hora_ingreso : '',
                fecha_salida : '',
                hora_salida : '',
                via_ingreso : '',
                causa_externa : '',
                dx_ingreso : '',
                pabellon : null,
                habitacion : null,
                cama : null,
                dx_salida : '',
                estado_salida : ''
            },
            estados_salida: [
                {
                    codigo: 1,
                    descripcion: "Vivo",
                },
                {
                    codigo: 2,
                    descripcion: "Muerto",
                },
            ],
            vias_ingreso : [
                {
                    codigo : 1,
                    descripcion : "Urgencias"
                },
                {
                    codigo : 2,
                    descripcion : "Consulta Externa o Programada"
                },
                {
                    codigo : 3,
                    descripcion : "Remitido",
                },
                {
                    codigo : 4,
                    descripcion: "Nacido en la Institucion"
                }
            ],
            pabellones : [],
            rips : this.value
        }
    },
    computed : {
        habitaciones () {

            if(isEmpty(this.pabellones)){
                return [];
            }

            return this.pabellones.find(x => x.ID === this.datos.pabellon)?.habitaciones;
        },
        camas (){

            if(isEmpty(this.pabellones)){
                return [];
            }

            if(isEmpty(this.habitaciones)){
                return [];
            }

            return this.habitaciones.find(x => x.ID === this.datos.habitacion)?.camas;
        }
    },
    watch : {
        datos: {
            handler: function () {

                this.rips = {
                    fecha_ingreso : this.datos.fecha_ingreso,
                    hora_ingreso : this.datos.hora_ingreso,
                    fecha_salida : this.datos.fecha_salida,
                    hora_salida : this.datos.hora_salida,
                    via_ingreso : this.datos.via_ingreso,
                    causa_externa : this.datos.causa_externa.id,
                    dx_ingreso : this.datos.dx_ingreso.ID_DIAG,
                    pabellon : this.datos.pabellon,
                    habitacion : this.datos.habitacion,
                    cama : this.datos.cama,
                    dx_salida : this.datos.dx_salida.ID_DIAG,
                    estado_salida : this.datos.estado_salida
                };

                this.$emit("input", this.rips);
            },
            deep: true,
        },
    }
}
</script>
