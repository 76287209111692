<template>
    <main :ref="random_ref">
        <data-grid @search="search($event)" :data="pagination">
            <template #grid>
                <grid-table>
                    <template #head>
                        <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                    </template>
                    <template #body>
                        <grid-row v-for="(finalidad, i) in pagination.data" :key="`d_${i}`">
                            <grid-cell class="text-center">
                                {{finalidad.codigo}}
                            </grid-cell>
                            <grid-cell>
                                {{finalidad.descripcion}}
                            </grid-cell>
                            <grid-cell class="text-center">
                                <button class="btn btn-sm btn-icon btn-success" title="Seleccionar" @click="selected(i)">
                                    <i class="fas fa-check"></i>
                                </button>
                            </grid-cell>
                        </grid-row>
                    </template>
                </grid-table>
            </template>
        </data-grid>
    </main>
</template>
<script>
import paginateMixin from "../../mixins/PaginateMixin";

import DataGrid from "./utilities/DataGrid/DataGrid";
import GridTable from "./utilities/DataGrid/GridTable";
import GridCellHeader from "./utilities/DataGrid/GridCellHeader";
import GridRow from "./utilities/DataGrid/GridRow";
import GridCell from "./utilities/DataGrid/GridCell";

import finalidadService from "../../services/finalidadService";

export default {
    name: "SearchFinalidadComponent",
    mixins : [paginateMixin(finalidadService)],
    components : {
        DataGrid,
        GridTable,
        GridCellHeader,
        GridRow,
        GridCell
    },
    data () {
        return {
            headers : ['Codigo','Descripcion',''],
            random_ref : ''
        }
    },
    mounted() {
        const random_number = Math.floor(Math.random() * ((100+1)-1)+1);
        this.random_ref = `search_dx_${random_number}_container`;
        this.$nextTick(() => {this.index();});
    },
    methods : {
        selected(index){
            const selected = this.pagination.data[index];
            this.$emit('select', selected);
        }
    }
}
</script>

<style scoped>

</style>
