<template>
    <main>
        <div class="modal fade" :id="random_modal_id" tabindex="-1" role="dialog" aria-labelledby="BuscarDiagnostico" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Buscar Diagnostico</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <component :is="component" @select="onSelect($event)"></component>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mr-0 pr-0 col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="input-group input-group-sm">
                    <input
                        type="text"
                        class="form-control"
                        :readonly="isSearch"
                        v-model="codigo"
                        v-on:keyup.enter.prevent="getByCode()"
                        :class="classObject"
                    />
                    <div class="input-group-append">
                        <button class="btn btn-primary" type="button" tabindex="-1" data-toggle="modal"
                                :data-target="`#${random_modal_id}`"
                                @click="component='SearchDxComponent'"
                        >
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="ml-0 pl-1 col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="nombreDiagnostico"
                    disabled
                />
            </div>
        </div>
    </main>
</template>
<script>
import {isEmpty} from 'lodash';
import $ from 'jquery';
import ServiceFactory from '../../services/ServiceFactory';
import SearchDxComponent from "./SearchDxComponent";
const DiagnosticoService = ServiceFactory.get("diagnostico");

export default {
    components: {SearchDxComponent},
    props: ["value", "validator", "default_value"],
    data() {
        return {
            codigo: "",
            diagnostico: this.value,
            isSearch: false,
            random_modal_id : '',
            component : ''
        };
    },
    created() {

        if (this.default_value !== "" && this.default_value !== undefined && this.default_value !== null) {
            this.codigo = this.default_value;
            this.getByCode();
        }

        if(typeof this.value === 'string'){
            this.codigo = this.value;
            this.getByCode();
        }

        const random_number = Math.floor(Math.random() * ((100+1)-1)+1);
        this.random_modal_id = `search_dx_modal_${random_number}`;
        console.log(this.random_modal_id);
    },
    watch: {
        value: {
            handler: function (newValue) {

                if(typeof newValue === 'string'){
                    this.codigo = newValue;
                    this.getByCode();
                    return;
                }

                this.diagnostico = newValue;

            },
            deep: true,
        },
        default_value: function (newValue) {
            if (newValue !== "" && newValue !== undefined && newValue !== null) {
                this.codigo = newValue;
                this.getByCode();
            }
        },
    },
    methods: {
        async getByCode() {

            this.isSearch = true;
            this.diagnostico = {};

            let response = null;

            response = await DiagnosticoService.getByCode(this.codigo);

            this.isSearch = false;

            if (!isEmpty(response.data)) {
                this.diagnostico = response.data;
            }

            this.$emit("input", this.diagnostico);
        },
        setChange() {
            if (this.validator !== undefined && this.validator !== null) {
                this.validator.$touch();
            }
        },
        onSelect(value){
            this.codigo = value.ID_DIAG;
            this.getByCode();
            $(`#${this.random_modal_id}`).modal('hide');
        }
    },
    computed: {
        nombreDiagnostico: function () {
            return !isEmpty(this.diagnostico) ? this.diagnostico.DESC_DIAG : "";
        },
        classObject: function () {

            let _class = {
                "is-invalid": false,
                "is-valid": false,
            };

            if (this.validator !== undefined && this.validator !== null) {
                _class["is-invalid"] = this.validator.$error;
                _class["is-valid"] = !this.validator.$error && this.validator.$dirty;
            }

            return _class;
        },
    },
};
</script>
