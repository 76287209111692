<template>
    <main>
        <div class="modal fade " :id="random_modal_id" tabindex="-1" role="dialog" aria-labelledby="BuscarSede" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Buscar Sede</h4>
                        <button type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <component @select="onSelect($event)" :is="component"></component>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mr-0 pr-0 col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="input-group input-group-sm">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        :readonly="isSearch"
                        v-on:keyup.enter.prevent="getSedeByCode()"
                        v-model="codigo"
                        :class="classObject"
                    />
                    <div class="input-group-append">
                        <button class="btn btn-primary"
                                data-toggle="modal"
                                :data-target="`#${random_modal_id}`"
                                tabindex="-1"
                                @click="component='SearchSedeComponent'"
                        >
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="ml-0 pl-1 col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <input
                    type="text"
                    class="form-control form-control-sm w-100"
                    disabled
                    v-model="sede.NOMBRE_SEDE"
                />
            </div>
        </div>
    </main>
</template>
<style scoped>
.form-inline .form-control{
    display: block !important;
}
</style>
<script>
import ServiceFactory from "../../services/ServiceFactory";
const SedeService = ServiceFactory.get("sede");
import SearchSedeComponent from "./SearchSedeComponent";
import {isEmpty} from 'lodash';
import $ from 'jquery';

export default {
    props: ["value", "validator"],
    components : {SearchSedeComponent},
    data() {
        return {
            codigo: "",
            sede: this.value,
            isSearch: false,
            component : '',
            random_modal_id : '',
        };
    },
    created() {
        const random_number = Math.floor(Math.random() * ((100+1)-1)+1);
        this.random_modal_id = `search_sede_modal_${random_number}`;
    },
    methods: {
        async getSedeByCode() {
            this.isSearch = true;

            let response = null;

            response = await SedeService.getSedeByCode(this.codigo);

            this.isSearch = false;

            if (!isEmpty(response.data)) {
                this.sede = response.data;
                this.$emit("input", this.sede);
            }
        },
        onSelect(value){
            this.codigo = value.ID;
            this.getSedeByCode();
            $(`#${this.random_modal_id}`).modal('hide');
        }
    },
    watch : {
        value: {
            handler: function (newValue) {
                this.sede = newValue;

                if (Object.entries(newValue).length === 0) {
                    this.codigo = "";
                    return;
                }

                this.codigo = newValue.ID;
            },
            deep: true,
        },
    },
    computed : {
        classObject: function () {

            let _class = {
                "is-invalid": false,
                "is-valid": false,
            };

            if (this.validator !== undefined && this.validator !== null) {
                _class["is-invalid"] = this.validator.$error;
                _class["is-valid"] = !this.validator.$error && this.validator.$dirty;
            }

            return _class;
        },
    }
};
</script>
<style scoped>
</style>
