<template>
<main>
    <div class="row">
        <div class="col-6 form-group">
            <label class="small">Fecha de Nacimiento</label>
            <input type="date" class="form-control form-control-sm" v-model="datos.fecha_nacimiento"
                   :class="{
                               'is-invalid': validator.fecha_nacimiento.$error,
                               'is-valid': ! validator.fecha_nacimiento.$error && validator.fecha_nacimiento.$dirty
                   }">
        </div>
        <div class="col-6 form-group">
            <label class="small">Hora de Nacimiento</label>
            <input type="time" class="form-control form-control-sm" v-model="datos.hora_nacimiento"
                   :class="{
                               'is-invalid': validator.hora_nacimiento.$error,
                               'is-valid': ! validator.hora_nacimiento.$error && validator.hora_nacimiento.$dirty
                   }">
        </div>
        <div class="col-6 form-group">
            <label class="small">Edad en Semanas</label>
            <input type="number" class="form-control form-control-sm" v-model="datos.edad_semanas"
                   :class="{
                               'is-invalid': validator.edad_semanas.$error,
                               'is-valid': ! validator.edad_semanas.$error && validator.edad_semanas.$dirty
                   }"
            >
        </div>
        <div class="col-6 form-group">
            <label class="small">Control Prenatal</label>
            <select class="form-control form-control-sm" v-model="datos.control_prenatal"
                    :class="{
                               'is-invalid': validator.control_prenatal.$error,
                               'is-valid': ! validator.control_prenatal.$error && validator.control_prenatal.$dirty
                   }"
            >
                <option value="1">SI</option>
                <option value="2">NO</option>
            </select>
        </div>
        <div class="col-6 form-group">
            <label class="small">Sexo</label>
            <select class="form-control form-control-sm" v-model="datos.sexo"
                    :class="{
                               'is-invalid': validator.sexo.$error,
                               'is-valid': ! validator.sexo.$error && validator.sexo.$dirty
                   }"
            >
                <option value="F">Femenino</option>
                <option value="M">Masculino</option>
            </select>
        </div>
        <div class="col-6 form-group">
            <label class="small">Peso en Gramos</label>
            <input class="form-control form-control-sm" v-model="datos.peso_en_gramos"
                   :class="{
                               'is-invalid': validator.peso_en_gramos.$error,
                               'is-valid': ! validator.peso_en_gramos.$error && validator.peso_en_gramos.$dirty
                   }"
            >
        </div>
        <div class="col-6 form-group">
            <label class="small">Dx Recien Nacido</label>
            <dx-field v-model="datos.dx_recien_nacido" :validator="validator.dx_recien_nacido"></dx-field>
        </div>
        <div class="col-6 form-group">
            <label class="small">Estado</label>
            <select class="form-control form-control-sm" v-model="datos.estado"
                    :class="{
                               'is-invalid': validator.estado.$error,
                               'is-valid': ! validator.estado.$error && validator.estado.$dirty
                   }"
            >
                <option value="V">Vivo</option>
                <option value="M">Muerto</option>
            </select>
        </div>
    </div>
</main>
</template>

<script>
import DxField from "../../../components/common/DxField";
export default {
    components: {DxField},
    props : ['value', 'validator'],
    data(){
        return {
            datos : {
                fecha_nacimiento : this.value?.fecha_nacimiento,
                hora_nacimiento : this.value?.hora_nacimiento,
                edad_semanas : this.value?.edad_semanas,
                control_prenatal : this.value?.control_prenatal,
                sexo : this.value?.sexo,
                peso_en_gramos : this.value?.peso_en_gramos,
                dx_recien_nacido : this.value?.dx_recien_nacido,
                estado : this.value?.estado
            },
            //rips : this.value,
        }
    },
    watch : {
        datos: {
            handler: function () {

                this.$emit("input", {
                    fecha_nacimiento : this.datos.fecha_nacimiento,
                    hora_nacimiento : this.datos.hora_nacimiento,
                    edad_semanas : this.datos.edad_semanas,
                    control_prenatal : this.datos.control_prenatal,
                    sexo : this.datos.sexo,
                    peso_en_gramos : this.datos.peso_en_gramos,
                    dx_recien_nacido : this.datos.dx_recien_nacido?.ID_DIAG,
                    estado : this.datos.estado
                });

            },
            deep: true,
        },
    }
}
</script>
