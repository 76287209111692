import { render, staticRenderFns } from "./ripsProcedimientos.vue?vue&type=template&id=f7235676&scoped=true&"
import script from "./ripsProcedimientos.vue?vue&type=script&lang=js&"
export * from "./ripsProcedimientos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7235676",
  null
  
)

export default component.exports