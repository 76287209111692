<template>
  <main>
    <div class="row">
      <div class="col-6" v-if="fields.finalidad">
        <div class="form-group">
          <label class="small mb-1">Finalidad</label>
          <select
            class="form-control form-control-sm"
            v-model="datos.finalidad"
            :class="finalidadClass"
          >
            <option value="">Selecciona una opción</option>
            <option
              v-for="(finalidad, index) in finalidades"
              :value="finalidad.codigo"
              :key="index"
            >
              {{ finalidad.descripcion }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6" v-if="fields.diagnostico_ppal">
        <div class="form-group">
          <label class="small mb-1">Dx. Ppal</label>
          <dx-field
            v-model="datos.diagnostico_ppal"
            :validator="
              validator.diagnostico_ppal !== undefined
                ? validator.diagnostico_ppal
                : null
            "
            :default_value="
              defaults_values.diagnostico_ppal !== undefined
                ? defaults_values.diagnostico_ppal
                : null
            "
          >
          </dx-field>
        </div>
      </div>
      <div class="col-6" v-if="fields.diagnostico_r">
        <div class="form-group">
          <label class="small mb-1">Dx. Relacionado</label>
          <dx-field v-model="datos.diagnostico_r"></dx-field>
        </div>
      </div>
      <div class="col-6" v-if="fields.personal_atiende">
        <div class="form-group">
          <label class="small mb-1">Personal Que Atiende</label>
          <select
            class="form-control form-control-sm"
            v-model="datos.personal_atiende"
            :class="personalAtiendeClass"
          >
            <option
              v-for="(personal, index) in personal"
              :key="index"
              :value="personal.codigo"
            >
              {{ personal.descripcion }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6" v-if="fields.forma_realizacion">
        <div class="form-group">
          <label class="small mb-1">Forma de Realización</label>
          <select class="form-control form-control-sm" :class="formaRealizacionClass" v-model="datos.forma_realizacion">
            <option
              v-for="(forma, index) in forma_realizacion"
              :key="index"
              :value="forma.codigo"
            >
              {{ forma.descripcion }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped>
</style>
<script>
import DxField from "../../../components/common/DxField.vue";

export default {
  props: ["value", "fields", "validator", "default"],
  components: {
    DxField,
  },
  created() {
    if (this.value !== null && this.value !== undefined) {
      this.defaults_values = this.value;
      this.defaults_values.diagnostico_ppal = this.value.diagnostico_ppal;
      this.datos.finalidad = this.value.finalidad;
    }
  },
  data() {
    return {
      rips: this.value,
      datos: {
        finalidad: "",
        diagnostico_ppal: "",
        diagnostico_r: "",
        personal_atiende: "",
        forma_realizacion: "",
      },
      defaults_values: {},
      personal: [
        {
          codigo: 1,
          descripcion: "Medico Especialista",
        },
        {
          codigo: 2,
          descripcion: "Medico General",
        },
        {
          codigo: 3,
          descripcion: "Enfermero (a)",
        },
        {
          codigo: 4,
          descripcion: "Auxiliar Enfermeria",
        },
        {
          codigo: 5,
          descripcion: "Otro",
        },
      ],
      forma_realizacion: [
        { codigo: 1, descripcion: "Unico Unilateral" },
        {
          codigo: 2,
          descripcion:
            "Multiple o bilateral, misma via, diferente especialidad",
        },
        {
          codigo: 3,
          descripcion: "Multiple o bilateral, misma via, igual especialidad",
        },
        {
          codigo: 4,
          descripcion:
            "Multiple o bilateral, diferente via, diferente especialidad",
        },
        {
          codigo: 5,
          descripcion:
            "Multiple o bilateral, diferente via, igual especialidad",
        },
      ],
      finalidades: [
        {
          codigo: 1,
          descripcion: "Diagnostico",
        },
        {
          codigo: 2,
          descripcion: "Terapeutico",
        },
        {
          codigo: 3,
          descripcion: "Protección específica",
        },
        {
          codigo: 4,
          descripcion: "Detección temprana de Enfermedad general",
        },
        {
          codigo: 5,
          descripcion: "Detección temprana de Enfermedad profesional",
        },
      ],
    };
  },
  watch: {
    datos: {
      handler: function () {
        this.rips = {
          finalidad: this.datos.finalidad,
          diagnostico_ppal: this.datos.diagnostico_ppal.ID_DIAG,
          diagnostico_r: this.datos.diagnostico_r.ID_DIAG,
          personal_atiende: this.datos.personal_atiende,
          forma_realizacion: this.datos.forma_realizacion,
        };

        this.$emit("input", this.rips);
        this.validate();
      },
      deep: true,
    },
    value: {
      handler: function () {
        if (this.value !== null && this.value !== undefined) {
          this.defaults_values = this.value;
          this.defaults_values.diagnostico_ppal = this.value.diagnostico_ppal;
          this.datos.finalidad = this.value.finalidad;
        }
      },
      deep: true,
    },
  },
  methods: {
    validate() {
      if (this.validator !== null && this.validator !== undefined) {
        this.validator.$touch();
      }
    },
  },
  computed: {
    finalidadClass: function () {
      if (
        this.validator.finalidad !== undefined ? this.validator.finalidad : null
      ) {
        let _class = {
          "is-invalid": false,
          "is-valid": false,
        };

        _class["is-invalid"] = this.validator.finalidad.$error;
        _class["is-valid"] =
          !this.validator.finalidad.$error && this.validator.finalidad.$dirty;

        return _class;
      }

      return {};
    },
    formaRealizacionClass: function () {
      if (
        this.validator.forma_realizacion !== undefined
          ? this.validator.forma_realizacion
          : null
      ) {
        let _class = {
          "is-invalid": false,
          "is-valid": false,
        };

        _class["is-invalid"] = this.validator.forma_realizacion.$error;
        _class["is-valid"] =
          !this.validator.forma_realizacion.$error &&
          this.validator.forma_realizacion.$dirty;

        return _class;
      }

      return {};
    },
    personalAtiendeClass: function () {
      if (
        this.validator.personal_atiende !== undefined
          ? this.validator.personal_atiende
          : null
      ) {
        let _class = {
          "is-invalid": false,
          "is-valid": false,
        };

        _class["is-invalid"] = this.validator.personal_atiende.$error;
        _class["is-valid"] =
          !this.validator.personal_atiende.$error &&
          this.validator.personal_atiende.$dirty;

        return _class;
      }

      return {};
    },
  },
};
</script>
