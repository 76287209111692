<template>
    <main>
        <data-grid @search="search($event)" :data="pagination">
            <template #grid>
                <grid-table>
                    <template #head>
                        <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                    </template>
                    <template #body>
                        <grid-row v-for="(tipo, i) in pagination.data" :key="`d_${i}`">
                            <grid-cell class="text-center">
                                {{tipo.codigo}}
                            </grid-cell>
                            <grid-cell>
                                {{tipo.nombre}}
                            </grid-cell>
                            <grid-cell class="text-center">
                                <button class="btn btn-sm btn-icon btn-success" title="Seleccionar" @click="selected(i)">
                                    <i class="fas fa-check"></i>
                                </button>
                            </grid-cell>
                        </grid-row>
                    </template>
                </grid-table>
            </template>
        </data-grid>
    </main>
</template>

<script>
import tipoDiagnosticoService from "../../services/tipoDiagnosticoService";
import paginateMixin from "../../mixins/PaginateMixin";
import GridCell from "./utilities/DataGrid/GridCell";
import GridRow from "./utilities/DataGrid/GridRow";
import GridCellHeader from "./utilities/DataGrid/GridCellHeader";
import GridTable from "./utilities/DataGrid/GridTable";
import DataGrid from "./utilities/DataGrid/DataGrid";

export default {
    name: "SearchTipoDiagnosticoComponent",
    mixins : [paginateMixin(tipoDiagnosticoService)],
    components: {GridCell, GridRow, GridCellHeader, GridTable, DataGrid},
    data ()  {
        return {
            headers : ['Codigo', 'Descripcion', ''],
        }
    },
    created() {
        this.index();
    },
    methods : {
        selected(index){
            const selected = this.pagination.data[index];
            this.$emit('select', selected);
        }
    }
}
</script>


