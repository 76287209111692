<template>
    <main>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Fecha Ingreso</label>
                    <input type="date" class="form-control form-control-sm"
                           v-model="datos.fecha_ingreso"
                           :class="{
                               'is-invalid': validator.fecha_ingreso.$error,
                               'is-valid': ! validator.fecha_ingreso.$error && validator.fecha_ingreso.$dirty
                           }"
                    />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Hora Ingreso</label>
                    <input type="time" class="form-control form-control-sm" v-model="datos.hora_ingreso"
                           :class="{
                               'is-invalid': validator.hora_ingreso.$error,
                               'is-valid': ! validator.hora_ingreso.$error && validator.hora_ingreso.$dirty
                           }"
                    />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Fecha Egreso</label>
                    <input type="date" class="form-control form-control-sm" v-model="datos.fecha_egreso"
                           :class="{
                               'is-invalid': validator.fecha_egreso.$error,
                               'is-valid': ! validator.fecha_egreso.$error && validator.fecha_egreso.$dirty
                           }"/>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Hora Egreso</label>
                    <input type="time" class="form-control form-control-sm" v-model="datos.hora_egreso"
                           :class="{
                               'is-invalid': validator.hora_egreso.$error,
                               'is-valid': ! validator.hora_egreso.$error && validator.hora_egreso.$dirty
                           }"
                    />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Causa Externa</label>
                    <causa-externa-field v-model="datos.causa_externa"
                                         :validator="validator.id_causa_externa"></causa-externa-field>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Diagnostico Salida</label>
                    <dx-field v-model="datos.diagnostico_ppal" :validator="validator.diagnostico_ppal"></dx-field>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Destino de Salida</label>
                    <select class="form-control form-control-sm" v-model="datos.destino_salida"
                            :class="{
                               'is-invalid': validator.destino_salida.$error,
                               'is-valid': ! validator.destino_salida.$error && validator.destino_salida.$dirty
                           }"
                    >
                        <option
                            v-for="(destino, index) in destinos"
                            :key="index"
                            :value="destino.codigo"
                        >
                            {{ destino.descripcion }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Estado de Salida</label>
                    <select class="form-control form-control-sm" v-model="datos.estado_salida"
                            :class="{
                               'is-invalid': validator.estado_salida.$error,
                               'is-valid': ! validator.estado_salida.$error && validator.estado_salida.$dirty
                           }"
                    >
                        <option
                            v-for="(estado, index) in estados_salida"
                            :key="index"
                            :value="estado.codigo"
                        >
                            {{ estado.descripcion }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Descripcion de la Urgencia</label>
                    <textarea class="form-control form-control-sm" v-model="datos.descripcion"
                              :class="{
                               'is-invalid': validator.descripcion.$error,
                               'is-valid': ! validator.descripcion.$error && validator.descripcion.$dirty
                           }"
                    ></textarea>
                </div>
            </div>
        </div>
    </main>
</template>
<style scoped>
</style>
<script>
import CausaExternaField from "../../../components/common/CausaExternaField.vue";
import DxField from "../../../components/common/DxField.vue";
import {isEmpty} from 'lodash';

export default {
    props: ["value", "validator"],
    components: {
        CausaExternaField,
        DxField,
    },
    data() {
        return {
            rips: this.value,
            datos: {
                fecha_ingreso: '',
                hora_ingreso: '',
                fecha_egreso: '',
                hora_egreso: '',
                tipo_diagnostico: "",
                causa_externa: "",
                diagnostico_ppal: "",
                destino_salida: '',
                diagnostico_r1: "",
                diagnostico_r2: "",
                diagnostico_r3: "",
                estado_salida: "",
                descripcion: ""
            },
            destinos: [
                {
                    codigo: 1,
                    descripcion: "Alta de Urgencias",
                },
                {
                    codigo: 2,
                    descripcion: "Remision",
                },
                {
                    codigo: 3,
                    descripcion: "Hospitalizacion",
                },
            ],
            estados_salida: [
                {
                    codigo: 1,
                    descripcion: "Vivo",
                },
                {
                    codigo: 2,
                    descripcion: "Muerto",
                },
            ],
        };
    },
    created () {
        if(!isEmpty(this.value)){

            console.log(this.value.diagnostico_ppal);

            this.datos = {
                fecha_ingreso: this.value.fecha_ingreso,
                hora_ingreso: this.value.hora_ingreso,
                fecha_egreso: this.value.fecha_egreso,
                hora_egreso: this.value.hora_egreso,
                tipo_diagnostico: this.value.tipo_diagnostico,
                causa_externa: this.value.id_causa_externa,
                diagnostico_ppal: this.value.diagnostico_ppal,
                destino_salida: this.value.destino_salida,
                diagnostico_r1: this.value.diagnostico_r1,
                diagnostico_r2: this.value.diagnostico_r2,
                diagnostico_r3: this.value.diagnostico_r3,
                estado_salida: this.value.estado_salida,
                descripcion: this.value.descripcion,
            };
        }
    },
    watch: {
        datos: {
            handler: function () {

               this.rips = {
                    fecha_ingreso: this.datos.fecha_ingreso,
                    hora_ingreso: this.datos.hora_ingreso,
                    fecha_egreso: this.datos.fecha_egreso,
                    hora_egreso: this.datos.hora_egreso,
                    id_causa_externa: this.datos.causa_externa.id,
                    diagnostico_ppal: this.datos.diagnostico_ppal.ID_DIAG,
                    diagnostico_r1: this.datos.diagnostico_r1.ID_DIAG,
                    diagnostico_r2: this.datos.diagnostico_r2.ID_DIAG,
                    diagnostico_r3: this.datos.diagnostico_r3.ID_DIAG,
                    destino_salida: this.datos.destino_salida,
                    estado_salida: this.datos.estado_salida,
                    descripcion: this.datos.descripcion
                };

                this.$emit("input", this.rips);
            },
            deep: true,
        },
    },
};
</script>
