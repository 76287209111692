<template>
    <main>
        <header
            class="page-header page-header-compact page-header-light border-bottom bg-white mb-4"
        >
            <div class="container-fluid">
                <div class="page-header-content">
                    <div class="row align-items-center justify-content-between pt-3">
                        <div class="col-auto mb-3">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"></div>
                                Factura de Eventos
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Main page content-->
        <div class="container-fluid mt-4">
            <div class="row">
                <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2">
                    <div class="card mt-2">
                        <div class="card-header text-uppercase">
                            Nueva Factura de Eventos
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label class="small mb-1">Ambito (*)</label>
                                    <select
                                        class="form-control form-control-sm"
                                        v-model="ambito"
                                        :class="{
                                            'is-invalid' : $v.ambito.$error,
                                            'is-valid' : !$v.ambito.$error && $v.ambito.$dirty
                                        }"
                                    >
                                        <option
                                            v-for="(ambito, index) in ambitos"
                                            :key="index"
                                            :value="ambito.idambito"
                                        >
                                            {{ ambito.desambito }}
                                        </option>
                                    </select>
                                    <span class="invalid-feedback" v-if="!$v.ambito.required && $v.ambito.$dirty">
                                        Este campo es requerido
                                    </span>
                                </div>
                                <div class="form-group col-6">
                                    <label class="small mb-1">Fecha</label>
                                    <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="fecha"
                                        :class="{
                                            'is-invalid' : $v.fecha.$error,
                                            'is-valid' : !$v.fecha.$error && $v.fecha.$dirty
                                        }"
                                    />
                                    <span class="invalid-feedback" v-if="!$v.fecha.required && $v.fecha.$dirty">
                                        Este campo es requerido
                                    </span>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12 form-group">
                                    <label class="small mb-1">Sede</label>
                                    <sede-field v-model="sede"
                                                :validator="$v.sede"></sede-field>
                                    <span class="text-danger" v-if="!$v.sede.required && $v.sede.$dirty">
                                        Este campo es requerido
                                    </span>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label class="small mb-1">Identificacion <small>(*)</small></label>
                                    <usuario-field v-model="usuario" :validator="$v.usuario"></usuario-field>
                                    <span class="text-danger" v-if="!$v.usuario.required && $v.usuario.$dirty">
                                        Este campo es requerido
                                    </span>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label class="small mb-1">Administradora (EPS) <small>(*)</small></label>
                                    <v-select-eps mode="object" v-model="eps" :validator="$v.eps"></v-select-eps>
                                    <span class="text-danger" v-if="$v.eps.$error && $v.eps.$dirty">
                                        Este campo es requerido
                                    </span>
                                </div>
                                <div class="form-group col-6">
                                    <label class="small mb-1">Contrato <small>(*)</small></label>
                                    <select
                                        name="contratos"
                                        id="contratos"
                                        class="form-control form-control-sm"
                                        v-model="contrato"
                                        :class="{
                                            'is-invalid' : $v.contrato.$error,
                                            'is-valid' : !$v.contrato.$error && $v.contrato.$dirty
                                        }"
                                    >
                                        <option selected disabled>
                                            -- Seleccione una opcion --
                                        </option>
                                        <option
                                            v-for="contrato in eps.contratos"
                                            :key="contrato.id"
                                            :value="contrato"
                                        >
                                            {{ contrato.codigo }} - {{ contrato.descripcion }}
                                        </option>
                                    </select>
                                    <span class="invalid-feedback" v-if="!$v.contrato.required && $v.contrato.$dirty">
                                        Este campo es requerido
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2">
                    <div class="card mt-2">
                        <div class="card-header text-uppercase">
                            Servicios Prestados al Usuario
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label class="small mb-1">Servicio</label>
                                    <servicio-field
                                        v-model="item.servicio"
                                        :tarifa="id_tarifa"
                                        :v="$v.item.servicio"
                                    ></servicio-field>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label class="small mb-1">Valor</label>
                                    <input
                                        type="number"
                                        class="form-control form-control-sm"
                                        v-model="$v.item.valor_servicio.$model"
                                        :class="{
                                            'is-invalid': $v.item.valor_servicio.$error,
                                            'is-valid': !$v.item.valor_servicio.$error && $v.item.valor_servicio.$dirty}"
                                    />
                                </div>
                                <div class="form-group col-6">
                                    <label class="small mb-1">Cantidad</label>
                                    <input
                                        type="number"
                                        class="form-control form-control-sm"
                                        v-model="$v.item.cantidad.$model"
                                        :disabled="disabled_cantidad"
                                        :class="{
                                            'is-invalid': $v.item.cantidad.$error,
                                            'is-valid': !$v.item.cantidad.$error && $v.item.cantidad.$dirty,
                                        }"
                                    />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12">
                                    <component
                                        v-bind:is="ripsServicioComponent"
                                        :full="false"
                                        :fields="ripsServiciosShowFieldsObject"
                                        :validator="$v.item.rips"
                                        v-model="item.rips"
                                    ></component>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <button
                                            class="btn btn-primary btn-sm shadow-sm"
                                            @click="agregarServicio()"
                                        >
                                            <i class="fa fa-plus"></i>
                                            Agregar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-borderless mb-0 table-sm table-services"
                                        >
                                            <caption class="sr-only">
                                                Servicios Prestados al Usuario
                                            </caption>
                                            <thead class="border-bottom">
                                            <tr class="small text-uppercase text-muted">
                                                <th scope="col">Servicio</th>
                                                <th scope="col">Valor</th>
                                                <th scope="col">Cant</th>
                                                <th scope="col">Valor Total</th>
                                                <th scope="col"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr
                                                class="border-bottom"
                                                v-for="(item, index) in items"
                                                :key="index"
                                            >
                                                <td>
                                                    <div class="font-weight-bold">
                                                        {{ item.servicio.codigo }}
                                                        {{ item.servicio.descripcion }}
                                                        <div class="small text-muted d-none d-md-block">
                                                            {{ item.servicio.agrupacion.codigo }}
                                                            {{ item.servicio.agrupacion.descripcion }}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {{ item.valor_servicio | cop_currency }}
                                                </td>
                                                <td>
                                                    {{ item.cantidad }}
                                                </td>
                                                <td>
                                                    {{
                                                        (item.valor_servicio * item.cantidad)
                                                            | cop_currency
                                                    }}
                                                </td>
                                                <td>
                                                    <button class="btn btn-transparent rounded-circle"
                                                            @click="removerServicio()">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-right pb-0" colspan="3">
                                                    <div
                                                        class="text-uppercase small font-weight-700 text-muted"
                                                    >
                                                        Total:
                                                    </div>
                                                </td>
                                                <td class="text-right pb-0">
                                                    <div class="h5 mb-0 font-weight-700">
                                                        {{ total_factura | cop_currency }}
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2">
                    <div class="card mt-2" v-show="ripsComponent !== ''">
                        <div class="card-header text-uppercase">
                            <h5 class="text-primary mb-1">Diligenciar Rips</h5>
                        </div>
                        <div class="card-body">
                            <component v-bind:is="ripsComponent" v-model="rips" :validator="$v.rips"></component>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="ripsRecienNacidoRequired">
                <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2">
                    <div class="card mt-2">
                        <div class="card-header text-uppercase">
                            <h5 class="text-primary mb-1">Rips Recien Nacido</h5>
                        </div>
                        <div class="card-body">
                            <rips-recien-nacido v-model="rips_recien_nacido"
                                                :validator="$v.rips_recien_nacido"></rips-recien-nacido>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div
                    class="col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2 mt-2 d-flex justify-content-end">
                    <button class="btn btn-light shadow-sm mb-1" @click="resetForm()">
                        <i class="fas fa-broom text-gray-600"></i>
                        Limpiar
                    </button>
                    <button class="btn btn-success shadow-sm mb-1 ml-2" @click="guardar()">
                        <i class="far fa-save text-white-50"></i>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </main>
</template>
<style scoped>

.v-select {
    height: calc(1.5em + 0.5rem + 2px) !important;
}

.v-select .vs__dropdown-toggle {
    height: calc(1.5em + 0.5rem + 2px) !important;
}

.table-services {
    font-size: 0.9rem;
}
</style>
<script type="text/javascript">
import {minValue, numeric, required} from "vuelidate/lib/validators";
import Swal from "sweetalert2/src/sweetalert2.js";
import moment from 'moment';
import {isEmpty} from 'lodash';

import SedeField from "../../../components/common/sedeField.vue";
import ServicioField from "../../../components/common/ServicioField.vue";
import UsuarioField from "../../../components/common/UsuarioField.vue";
import VSelectDx from "../../../components/common/VSelectDx.vue";
import VSelectEps from "../../../components/common/VSelectEps";
import ServiceFactory from "../../../services/ServiceFactory";
import RipConsulta from "../rips/ripConsulta";
import RipsUrgencia from "../rips/ripsUrgencia";
import RipsProcedimientos from "../rips/ripsProcedimientos";
import RipsHospitalizacion from "../rips/RipsHospitalizacion";
import RipsRecienNacido from "../rips/RipsRecienNacido";

const AmbitoService = ServiceFactory.get("ambito");
const FacturaService = ServiceFactory.get("factura");

const greaterThan = (fecha_inicio, hora_inicio, hora_finalizacion) => (value) => {
    return moment(`${value} ${hora_finalizacion}`).isAfter(`${fecha_inicio} ${hora_inicio}`);
}

export default {
    components: {
        RipsRecienNacido,
        VSelectEps,
        UsuarioField,
        ServicioField,
        SedeField,
        VSelectDx,
        RipConsulta,
        RipsUrgencia,
        RipsProcedimientos,
        RipsHospitalizacion
    },
    created() {
        AmbitoService.list().then((response) => {
            this.ambitos = response.data;
        });
    },
    data: () => ({
        eps: {
            contratos: [],
        },
        rips: {},
        rips_recien_nacido: {},
        usuario: {},
        contrato: {},
        sede: {},
        dx: {},
        item: {
            servicio: {},
            cantidad: 1,
            valor_servicio: 0,
            rips: {},
        },
        ambito: "",
        fecha: "",
        items: [],
        ambitos: [],
    }),
    validations() {
        return {
            eps: {
                required,
                contratos: {required}
            },
            usuario: {required},
            contrato: {required},
            sede: {required},
            ambito: {required},
            fecha: {required},
            ...this.validationObject
        }
    },
    methods: {
        agregarServicio() {

            this.$v.item.$touch();

            if (this.$v.item.$invalid) {
                return;
            }

            const agrupacion_servicio = this.item.servicio.agrupacion.codigo;

            const agrupar_servicio = ["12", "09", "13"].includes(agrupacion_servicio);

            if (!agrupar_servicio) {
                const ciclos = this.item.cantidad;
                for (let i = 0; i < ciclos; i++) {
                    this.item.cantidad = 1;
                    this.items.push({...this.item});
                }
            } else {
                this.items.push({...this.item});
            }

            this.item.servicio = {};
            this.item.rips = {};
            this.item.cantidad = 1;

            this.$v.item.$reset();
        },
        removerServicio(index) {
            this.items.splice(index, 1);
        },
        async guardar() {
            try {

                this.$v.eps.$touch();
                this.$v.usuario.$touch();
                this.$v.contrato.$touch();
                this.$v.sede.$touch();
                this.$v.ambito.$touch();
                this.$v.fecha.$touch();
                this.$v.rips.$touch();

                if (this.$v.eps.$invalid ||
                    this.$v.usuario.$invalid ||
                    this.$v.contrato.$invalid ||
                    this.$v.sede.$invalid ||
                    this.$v.ambito.$invalid ||
                    this.$v.fecha.$invalid ||
                    this.$v.rips.$invalid) return;


                if (isEmpty(this.items)) {
                    Swal.fire({text: "Debe agregar por lo menos un servicio", icon: "error"});
                    return;
                }

                if (this.ripsRecienNacidoRequired) {
                    this.$v.rips_recien_nacido.$touch();
                    if (this.$v.rips_recien_nacido.$invalid) {
                        return;
                    }
                }

                this.LoaderSpinnerShow();

                const factura = {
                    id_ambito: this.ambito,
                    fecha: this.fecha,
                    id_sede: this.sede.ID,
                    usuario: this.usuario,
                    id_eps: this.eps.id,
                    id_contrato: this.contrato.id,
                    detalles: this.items,
                    valor_total: this.total_factura,
                    valor_usuario: 0,
                    valor_neto: this.total_neto,
                    rips: this.rips,
                    rips_recien_nacido: this.rips_recien_nacido,
                };

                let response = await FacturaService.save(factura);

                this.LoaderSpinnerHide();

                Swal.fire({
                    text: `Factura Guardada con Exito Nro ${response.data.prefijo} ${response.data.numero}`,
                    icon: "success",
                });

                this.resetForm();

            } catch (ex) {
                this.LoaderSpinnerHide();

                console.error(ex);

                Swal.fire(
                    'Ocurrio un error al procesar la solicitud',
                    'si el problema persiste pongase en contacto con el administrador del sistema',
                    'error'
                );
            }
        },
        resetForm() {
            this.ambito = '';
            this.fecha = '';
            this.sede = {};
            this.usuario = {};

            this.eps = {
                contratos: {}
            };

            this.contrato = {};
            this.items = [];
            this.rips = {};

            this.$v.$reset();
        }
    },
    watch: {
        ripsServicioObject: {
            handler: function (value) {
                this.item.rips = value;
            },
            deep: true
        },
        "item.servicio": {
            handler: function (value) {
                this.item.valor_servicio = Number(this.valor_servicio);

                if (Object.entries(value).length) {

                    this.$v.item.valor_servicio.$touch();
                    this.$v.item.cantidad.$touch();

                    if (value.agrupacion.codigo === "01") {
                        this.item.cantidad = 1;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        nombre_servicio: function () {
            return !isEmpty(this.item.servicio)
                ? `${this.item.servicio.descripcion}`
                : "";
        },
        id_tarifa: function () {
            return !isEmpty(this.contrato) ? this.contrato.id_tarifa + "" : "";
        },
        valor_servicio: function () {
            if (isEmpty(this.item.servicio)) {
                return 0;
            }

            if (!isEmpty(this.item.servicio.tarifas)) {
                let descuento =
                    this.item.servicio.tarifas[0].pivot.valor *
                    (this.contrato.descuento / 100);

                let valor = this.item.servicio.tarifas[0].pivot.valor - descuento;

                let sobrante = Number((valor + "").substr(-2));

                return Number(valor - sobrante);
            }

            return 0;
        },
        total_factura: function () {

            if (isEmpty(this.items)) {
                return 0;
            }

            let total_factura = this.items.reduce((total, item) => {
                return total + item.valor_servicio * item.cantidad;
            }, 0);

            return total_factura;
        },
        ripsServicioComponent: function () {
            if (Object.entries(this.item.servicio).length > 0) {
                if (this.item.servicio.agrupacion.codigo === "01") {
                    return "RipConsulta";
                } else if (
                    ["02", "03", "04", "05"].includes(
                        this.item.servicio.agrupacion.codigo
                    )
                ) {
                    return "RipsProcedimientos";
                }

                return '';
            }

            return '';
        },
        ripsServicioObject: function () {
            if (this.ripsServicioComponent !== "") {
                if (this.ripsServicioComponent === "RipConsulta") {
                    return {
                        id_tipo_diagnostico: 1,
                        id_finalidad: 10,
                        id_causa_externa: 13,
                        diagnostico_ppal: "",
                        diagnostico_r1: "",
                        diagnostico_r2: "",
                        diagnostico_r3: "",
                    };
                } else if (this.ripsServicioComponent === "RipsProcedimientos") {
                    if (this.item.servicio.agrupacion.codigo === "02") {
                        return {
                            finalidad: 1,
                            diagnostico_ppal: "Z017",
                            diagnostico_r: "",
                            personal_atiende: "",
                            forma_realizacion: "",
                        };
                    } else if (this.item.servicio.agrupacion.codigo === "03") {
                        return {
                            finalidad: 1,
                            diagnostico_ppal: "Z017",
                            diagnostico_r: "",
                            personal_atiende: "",
                            forma_realizacion: "",
                        };
                    } else if (this.item.servicio.agrupacion.codigo === "04") {
                        return {
                            finalidad: 1,
                            diagnostico_ppal: "",
                            personal_atiende: 2,
                            forma_realizacion: 1,
                        };
                    } else if (this.item.servicio.agrupacion.codigo === "05") {
                        return {
                            finalidad: 1,
                            diagnostico_ppal: "Z017",
                            diagnostico_r: "",
                            personal_atiende: "",
                            forma_realizacion: "",
                        };
                    } else if (this.item.servicio.agrupacion.codigo === "10") {
                        return {
                            finalidad: 1,
                            diagnostico_ppal: "Z017",
                            diagnostico_r: "",
                            personal_atiende: "",
                            forma_realizacion: "",
                        };
                    }
                }
                return '';
            }

            if (!isEmpty(this.item.servicio)) {
                if (this.item.servicio.agrupacion.codigo === "06") {
                    return {tipo: "3"};
                } else if (this.item.servicio.agrupacion.codigo === "07") {
                    return {tipo: "4"};
                } else if (this.item.servicio.agrupacion.codigo === "08") {
                    return {tipo: "3"};
                } else if (this.item.servicio.agrupacion.codigo === "09") {
                    return {tipo: "1"};
                } else if (this.item.servicio.agrupacion.codigo === "11") {
                    return {tipo: "1"};
                } else if (this.item.servicio.agrupacion.codigo === "12") {
                    return {tipo: "1"};
                } else if (this.item.servicio.agrupacion.codigo === "13") {
                    return {tipo: "2"};
                } else if (this.item.servicio.agrupacion.codigo === "14") {
                    return {tipo: "2"};
                }
            }

            return '';
        },
        ripsComponent: function () {

            if (this.ambito === 3) {
                return "RipsUrgencia";
            }

            if (this.ambito === 2) {
                return "RipsHospitalizacion";
            }

            return "";
        },
        ripsServiciosShowFieldsObject: function () {
            if (this.ripsServicioComponent === "RipConsulta") {
                return {
                    tipo_diagnostico: true,
                    finalidad: true,
                    causa_externa: true,
                    diagnostico_ppal: true,
                    diagnostico_r1: false,
                    diagnostico_r2: false,
                    diagnostico_r3: false,
                };
            } else if (this.ripsServicioComponent === "RipsProcedimientos") {
                if (this.item.servicio.agrupacion.codigo === "02") {
                    return {
                        finalidad: true,
                        diagnostico_ppal: true,
                        diagnostico_r: false,
                        personal_atiende: false,
                        forma_realizacion: false,
                    };
                } else if (this.item.servicio.agrupacion.codigo === "03") {
                    return {
                        finalidad: true,
                        diagnostico_ppal: true,
                        diagnostico_r: false,
                        personal_atiende: false,
                        forma_realizacion: false,
                    };
                } else if (this.item.servicio.agrupacion.codigo === "04") {
                    return {
                        finalidad: true,
                        diagnostico_ppal: true,
                        diagnostico_r: false,
                        personal_atiende: true,
                        forma_realizacion: true,
                    };
                } else if (this.item.servicio.agrupacion.codigo === "05") {
                    return {
                        finalidad: true,
                        diagnostico_ppal: true,
                        diagnostico_r: false,
                        personal_atiende: false,
                        forma_realizacion: false,
                    };
                }

                return '';
            }

            return '';
        },
        total_neto: function () {
            return this.total_factura;
        },
        disabled_cantidad: function () {

            if (isEmpty(this.item.servicio)) {
                return false;
            }

            return this.item.servicio.agrupacion.codigo === "01";
        },
        ripsComponentValidation: function () {
            if (this.ripsComponent === 'RipsHospitalizacion') {
                return {
                    fecha_ingreso: {required},
                    hora_ingreso: {required},
                    fecha_salida: {
                        required,
                        greaterThan: greaterThan(this.rips.fecha_ingreso, this.rips.hora_ingreso, this.rips.hora_salida)
                    },
                    hora_salida: {required},
                    via_ingreso: {required},
                    causa_externa: {required},
                    dx_ingreso: {required},
                    pabellon: {required},
                    habitacion: {required},
                    cama: {required},
                    dx_salida: {required},
                    estado_salida: {required}
                }
            }

            if (this.ripsComponent === 'RipsUrgencia') {
                return {
                    fecha_ingreso: {required},
                    hora_ingreso: {required},
                    fecha_egreso: {
                        required,
                        greaterThan: greaterThan(this.rips.fecha_ingreso, this.rips.hora_ingreso, this.rips.hora_egreso)
                    },
                    hora_egreso: {required},
                    id_causa_externa: {required},
                    diagnostico_ppal: {required},
                    destino_salida: {required},
                    estado_salida: {required},
                    descripcion: {required}
                }
            }

            return {};
        },
        ripsRecienNacidoRequired: function () {

            if (isEmpty(this.items)) {
                return false;
            }

            let hasCode = this.items.find(x => x.servicio.codigo === '735910');

            return !isEmpty(hasCode);
        },
        ripsRecienNacidoValidation: function () {

            if (!this.ripsRecienNacidoRequired) {
                return {};
            }

            return {
                fecha_nacimiento: {required},
                hora_nacimiento: {required},
                edad_semanas: {required},
                control_prenatal: {required},
                sexo: {required},
                peso_en_gramos: {required},
                dx_recien_nacido: {required},
                estado: {required}
            }
        },
        validationObject: function () {
            if (this.ripsServicioComponent === "RipConsulta") {
                return {
                    item: {
                        servicio: {required},
                        valor_servicio: {required, numeric, minValue: minValue(1)},
                        cantidad: {required, numeric},
                        rips: {
                            id_tipo_diagnostico: {required},
                            id_finalidad: {required},
                            id_causa_externa: {required},
                            diagnostico_ppal: {required},
                        },
                    },
                    rips: this.ripsComponentValidation,
                    rips_recien_nacido: this.ripsRecienNacidoValidation,
                };
            } else if (this.ripsServicioComponent === "RipsProcedimientos") {
                if (this.item.servicio.agrupacion.codigo === "02") {
                    return {
                        item: {
                            servicio: {required},
                            valor_servicio: {required, numeric, minValue: minValue(1)},
                            cantidad: {required, numeric},
                            rips: {
                                finalidad: {required},
                                diagnostico_ppal: {required},
                            },
                        },
                        rips: this.ripsComponentValidation,
                        rips_recien_nacido: this.ripsRecienNacidoValidation,
                    };
                } else if (this.item.servicio.agrupacion.codigo === "03") {
                    return {
                        item: {
                            servicio: {required},
                            valor_servicio: {required, numeric, minValue: minValue(1)},
                            cantidad: {required, numeric},
                            rips: {
                                finalidad: {required},
                                diagnostico_ppal: {required},
                            },
                        },
                        rips: this.ripsComponentValidation,
                        rips_recien_nacido: this.ripsRecienNacidoValidation,
                    };
                } else if (this.item.servicio.agrupacion.codigo === "04") {
                    return {
                        item: {
                            servicio: {required},
                            valor_servicio: {required, numeric, minValue: minValue(1)},
                            cantidad: {required, numeric},
                            rips: {
                                diagnostico_ppal: {required},
                                forma_realizacion: {required},
                                personal_atiende: {required},
                            },
                        },
                        rips: this.ripsComponentValidation,
                        rips_recien_nacido: this.ripsRecienNacidoValidation,
                    };
                } else if (this.item.servicio.agrupacion.codigo === "05") {
                    return {
                        item: {
                            servicio: {required},
                            valor_servicio: {required, numeric, minValue: minValue(1)},
                            cantidad: {required, numeric},
                            rips: {
                                finalidad: {required},
                                diagnostico_ppal: {required},
                            },
                        },
                        rips: this.ripsComponentValidation,
                        rips_recien_nacido: this.ripsRecienNacidoValidation,
                    };
                }
            } else {
                return {
                    item: {
                        servicio: {required},
                        valor_servicio: {required, numeric, minValue: minValue(1)},
                        cantidad: {required, numeric},
                    },
                    rips: this.ripsComponentValidation,
                    rips_recien_nacido: this.ripsRecienNacidoValidation,
                };
            }

            return {};
        }
    },
};
</script>
