<template>
    <main>
        <div class="row">
            <div class="mr-0 pr-0 col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="input-group input-group-sm">
                    <input
                        type="text"
                        class="form-control"
                        :readonly="isSearch"
                        v-model="codigo"
                        @keyup.enter.prevent="getByCode()"
                        @input="validator.$touch()"
                        :class="{'is-invalid': validator.$error,'is-valid': ! validator.$error && validator.$dirty}"
                    />
                    <div class="input-group-append">
                        <button class="btn btn-primary" type="button" tabindex="-1"
                                data-toggle="modal"
                                :data-target="`#${this.random_modal_id}`"
                                @click="component='SearchTipoDiagnosticoComponent'">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="ml-0 pl-1 col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    disabled
                    v-model="tipoDiagnostico.nombre"
                />
            </div>
        </div>
        <div class="modal fade" :id="random_modal_id" tabindex="-1" role="dialog" aria-labelledby="BuscarSede" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Buscar Tipo Diagnostico</h4>
                        <button type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <component @select="onSelect($event)" :is="component"></component>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import ServiceFactory from "../../services/ServiceFactory";
const TipoDiagnosticoService = ServiceFactory.get("tipo-diagnostico");
import SearchTipoDiagnosticoComponent from "./SearchTipoDiagnosticoComponent";
import {isEmpty} from 'lodash';
import $ from 'jquery';

export default {
    props: ["value", "default", 'validator'],
    components : {SearchTipoDiagnosticoComponent},
    data() {
        return {
            codigo: "",
            tipoDiagnostico: this.value,
            isSearch: false,
            component : '',
            random_modal_id : '',
        };
    },
    created() {
        if (this.default !== "") {
            this.codigo = this.default;
            this.getByCode();
        }
        const random_number = Math.floor(Math.random() * ((100+1)-1)+1);
        this.random_modal_id = `search_tipo_dx_modal_${random_number}`;
    },
    methods: {
        async getByCode() {
            this.isSearch = true;

            let response = null;

            response = await TipoDiagnosticoService.getByCode(this.codigo);

            this.isSearch = false;

            if (!isEmpty(response.data)) {
                this.tipoDiagnostico = response.data;
                this.$emit("input", this.tipoDiagnostico);
            }
        },
        setChange(value) {
            this.$emit("update-value", value);
        },
        onSelect(value){
            this.codigo = value.codigo;
            this.getByCode();
            $(`#${this.random_modal_id}`).modal('hide');
        }
    },
};
</script>
<style scoped>
</style>
